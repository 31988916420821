import React from 'react';

export default function Footer() {
  return (
    <footer id='tail'>

      <div id='copyright'>
          <span>
            © 2021 <a href='scooter83.cz/' title='Scooter servis Praha'>scooter83.cz</a>
          </span>
      </div>
      <div id='created'>
          <span>
            created by <a href='https://web.archive.orgwww.presne.cz/'
                          title='webové aplikace'>presne.cz</a>
          </span>
      </div>

    </footer>
  );
}
