import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, StaticQuery } from 'gatsby';

import '../assets/sass/main.scss';
import Footer from './Footer';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children, fullMenu, subPage } = this.props;
    const { isPreloaded } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <div
              className='main'
            >
              <div id='top'>
                <div id='top_left'>
                  <div id='links'>
                    <table cellPadding='0' cellSpacing='0'>
                      <tbody>
                      <tr>
                        <td className='tdleft'><img src='scooter83.cz/res/images/medved.gif'
                                                    alt='' /></td>
                        <td className='tdright'><Link to='/' title='ÚVOD'>ÚVOD</Link></td>
                      </tr>
                      <tr>
                        <td className='tdleft'><img src='scooter83.cz/res/images/medved.gif'
                                                    alt='' /></td>
                        <td className='tdright'><Link to='/kontakt'
                                                      title='KONTAKT'>KONTAKT</Link></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id='top_right'>
                  <h1><Link to="/" title='Skútr servis Praha'></Link></h1>
                </div>
                <div className='cleaner' />
              </div>
              {children}
              <Footer />
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  subPage: PropTypes.bool,
};

export default Layout;
